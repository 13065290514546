import { SET_LOADER } from '../actionTypes/LoaderActionType'

const initialState = {
    loaderShow: false
  }
  
export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LOADER:
        return {
            ...state, 
            loaderShow: action.payload
        }
      default:
        return state
    }
  }

  export default loaderReducer;
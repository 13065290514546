import React, { Component } from "react";
import Root from "./Root";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import requireAuth from "./utils/RequireAuth";
import "./scss/style.scss";
import {runLogoutTimer} from './redux/actions/LoginActions';
import axios from "axios";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

if (window.location.origin === "http://localhost:3000") {
  axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
  axios.defaults.baseURL = window.location.origin;
}

const Login = React.lazy(() => import("./views/pages/login"));
const ResendActivation = React.lazy(() => import("./views/pages/account/ResendActivation"));
const ActivateAccount = React.lazy(() => import("./views/pages/account/ActivateAccount"));
const ResetPassword = React.lazy(() => import("./views/pages/account/ResetPassword"));
const ResetPasswordConfirm = React.lazy(() => import("./views/pages/account/ResetPasswordConfirm"));
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: [],
    };
  }

  componentDidMount = () => {
    runLogoutTimer(localStorage.getItem("loginTime"));
  }

  render() {
    return (
      <div>
        <Root>
          <React.Suspense fallback={loading}>
            <ToastContainer hideProgressBar={true} newestOnTop={true} />
            <Switch>
              <Route exact path="/" component={requireAuth(TheLayout)} />
              <Route path="/layout" component={requireAuth(TheLayout)} />
              <Route path="/login" component={Login} />
              <Route path="/resend_activation" component={ResendActivation} />
              <Route path="/activate/:uid/:token" component={ActivateAccount} />
              <Route path="/send_reset_password/" component={ResetPassword} />
              <Route
                path="/reset_password/:uid/:token"
                component={ResetPasswordConfirm}
              />
            </Switch>
          </React.Suspense>
        </Root>
      </div>
    );
  }
}

export default App;

import { SET_SIDEBAR } from '../actionTypes/TheSidebarActionType'

const initialState = {
    sidebarShow: 'responsive'
  }
  
export const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SIDEBAR:
        return {
            ...state, 
            sidebarShow: action.payload
        }
      default:
        return state
    }
  }

  export default sidebarReducer;
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginReducer from "./reducers/LoginReducer";
import sidebarReducer from "./reducers/TheSidebarReducer";
import loaderReducer from "./reducers/LoaderReducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: loginReducer,
    sidebar: sidebarReducer,
    loader:loaderReducer,
  });

export default createRootReducer;
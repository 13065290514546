import axios from "axios";
import moment from "moment";
import { string } from "prop-types";
import { toast } from "react-toastify";

export const setAxiosAuthToken = (token) => {
  if (typeof token !== "undefined" && token) {
    // Apply for every request
    axios.defaults.headers.Authorization = "Token " + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.Authorization;
  }
};

export const toastOnError = (error) => {
  if (error.response) {
    // known error
    toast.error(error.response.data.non_field_errors[0]);
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error(error);
  }
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const nameValidator = (name) => {
  if (!name || name.trim().length <= 0) {
    return "Username cannot be empty";
  }
  return "";
};

export const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.trim().length <= 0) {
    return "Email cannot be empty";
  }
  if (!re.test(email)) {
    return "We need valid email address";
  }
  return "";
};

export const mobileValidator = (mobile) => {
  const re =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{2,4})(?: *x(\d+))?\s*$/;

  if (!mobile || mobile.trim().length <= 0) {
    return "Mobile number cannot be empty";
  }
  if (!re.test(mobile)) {
    return "e.g. +968 12345678";
  }

  return "";
};

export const passwordValidator = (password) => {
  if (!password || password.trim().length <= 0) {
    return "Password cannot be empty";
  }
  return "";
};

export const userPasswordValidator = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  if (!password || password.trim().length <= 0) {
    return "Password cannot be empty";
  }
  if (!re.test(password)) {
    return `Your password should contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.`;
  }
  return "";
};

export const listMatchCampaignValidator = (id) => {
  if (id === "") {
    return "List match campaign ID cannot be empty";
  }
  if (id <= 0) {
    return "Please enter valid campaign ID";
  }
  return "";
};

export const beeswaxCampaignValidator = (val) => {
  if (!val || val.trim().length <= 0) {
    return "Beeswax campaign ID cannot be empty";
  }
  return "";
};

export const admanagerCampaignValidator = (val) => {
  if (!val || val.trim().length <= 0) {
    return "Ad Manager campaign ID cannot be empty";
  }
  return "";
};

export const startDateValidator = (date) => {
  if (!date || date.trim().length <= 0) {
    return "Start date cannot be empty";
  }
  // if (moment().format("YYYY-MM-DD") > moment(date).format("YYYY-MM-DD")) {
  //   return "Start date should be grater than or equal to present date";
  // }

  if (moment(date).year() >= 9999) {
    return "Please enter valid date";
  }

  return "";
};

export const endDateValidator = (end_date, start_date) => {
  if (!end_date || end_date.length <= 0) {
    return "End date cannot be empty";
  }

  // if (moment().format("YYYY-MM-DD") > moment(end_date).format("YYYY-MM-DD")) {
  //   return "End date should be grater than or equal to present date";
  // }

  if (moment(end_date).year() >= 9999) {
    return "Please enter valid date";
  }

  if (moment(start_date).isAfter(end_date)) {
    return "End date should be grater than or equal to start date";
  }

  return "";
};

export const fileValidation = (file, data) => {
  if (!file) {
    return "File cannot be empty";
  }
  if(file && data.length > 0){
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
    let duplicateVal = findDuplicates(data.map(h => h.toLowerCase().replace(/ /g,"")))
    if(duplicateVal.length <= 0){
      return "";
    }else{
      return "The column header should not appear twice!";
    }
  }
}

export const dataValidator = (data, string, start_date) => {
  if (string === "file") {
    if (!data) {
      return "File cannot be empty";
    }
  } else if (!data || data.length <= 0) {
    if (string === "IO") {
      return "IO cannot be empty";
    }
    if (string === "name") {
      return "Name cannot be empty";
    }
    if (string === "brand") {
      return "Brand cannot be empty";
    }
    if (string === "size") {
      return "Size cannot be empty";
    }
    if (string === "start") {
      return "Start date cannot be empty";
    }
    if (string === "end") {
      return "End date cannot be empty";
    }
    if (string === "requires") {
      return "Requires PID cannot be empty";
    }
    if (string === "service") {
      return "Client Service ID cannot be empty";
    }
    if (string === "account") {
      return "Account Details cannot be empty";
    }
    if (string === "trafficker") {
      return "Trafficker cannot be empty";
    }
  } else if (string === "end" && moment(start_date).isAfter(data)) {
    return "End date should be grater than or equal to start date";
  }
  return "";
};

export const userValidator = (data, string) => {
  if (string === "first_name" && data.trim().length <= 0) {
    return "First name cannot be empty";
  }

  if (string === "last_name" && data.trim().length <= 0) {
    return "Last name cannot be empty";
  }

  if (string === "username" && data.trim().length <= 0) {
    return "Username cannot be empty";
  }

  const re = /\S+@\S+\.\S+/;

  if (data.trim().length <= 0 && string === "email") {
    return "Email cannot be empty";
  } else if (data.trim().length > 0 && string === "email" && !re.test(data)) {
    return "We need valid email address";
  }

  return "";
};
import axios from "axios";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import {
  SET_TOKEN,
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
} from "./../actionTypes/LoginTypes";
import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";
import moment from "moment";
import { setLoaderState } from "./LoaderActions";

const wait = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const login = (userData, redirectTo) => (dispatch) => {
  axios
    .post("/api/v1/token/login/", userData)
    .then((response) => {
      const { auth_token } = response.data;
      toast.success("Login successful.");
      wait(1000).then(() => {
        localStorage.removeItem("loginTime");
        localStorage.setItem("loginTime", moment());
        runLogoutTimer(moment());
        setAxiosAuthToken(auth_token);
        dispatch(setToken(auth_token));
        dispatch(getCurrentUser(redirectTo));
      
      });
    })
    .catch((error) => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
      dispatch(setLoaderState(false));
    });
};

export const getCurrentUser = (redirectTo) => (dispatch) => {
  let user = {};
  axios
    .get("/api/v1/users/me/")
    .then(async (response) => {
      await axios
        .get(`api/v1/profile/${response.data.email}/`)
        .then((res) => {
          user = {
            username: response.data.username,
            email: response.data.email,
            is_superuser: res.data.is_superuser,
            role: res.data.role
          };
        })
        .catch((err) => {
          console.log(err);
        });
      dispatch(setCurrentUser(user, redirectTo));
    })
    .catch((error) => {
      dispatch(unsetCurrentUser());
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.hasOwnProperty("data") &&
          error.response.data.hasOwnProperty("detail") &&
          error.response.data["detail"] === "User inactive or deleted."
        ) {
          dispatch(push("/resend_activation"));
        }
      } else {
        toastOnError(error);
      }
    });
};

export const setCurrentUser = (user, redirectTo) => (dispatch) => {
  localStorage.setItem("user", JSON.stringify(user));
  dispatch({
    type: SET_CURRENT_USER,
    payload: user,
  });

  if (redirectTo !== "") {
    dispatch(push(redirectTo));
  }
  dispatch(setLoaderState(false));

  if (!window.location.hash) {
    window.location = window.location + "#loaded";
    window.location.reload();
  }
};

export const setToken = (token) => (dispatch) => {
  setAxiosAuthToken(token);
  localStorage.setItem("token", token);
  dispatch({
    type: SET_TOKEN,
    payload: token,
  });
};

export const unsetCurrentUser = () => (dispatch) => {
  setAxiosAuthToken("");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("loginTime");
  dispatch({
    type: UNSET_CURRENT_USER,
  });
};

export const logout = () => (dispatch) => {
  axios
    .post("/api/v1/token/logout/")
    .then((response) => {
      dispatch(unsetCurrentUser());
      dispatch(push("/"));
      toast.success("Logout successful.");
      dispatch(setLoaderState(false));
    })
    .catch((error) => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
      dispatch(setLoaderState(false));
    });
};

export const runLogoutTimer = (time) => {
  const now = moment();
  const end = moment(time);
  const duration = moment.duration(now.diff(end));
  const minutes = duration.asMinutes().toFixed(0);

  if (!isNaN(minutes) && minutes >= 60) {
    localStorage.clear();
    window.location.reload();
  } else if (!isNaN(minutes) && minutes <= 60) {
    let timer = (60 - minutes) * 60 * 1000;
    setTimeout(() => {
      toast.error("Session Timeout");
      wait(2000).then(() => {
        localStorage.clear();
        window.location.reload();
      });
    }, timer);
  }
};
